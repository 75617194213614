import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import { IoIosArrowDropupCircle } from "react-icons/io";

// Import components
import Home from './pages/Home';
import Contact from './pages/Contact';
import Healthcare from './components/Healthcare';
import ScrollToTop from './components/ScrollToTop';
import { useDocTitle } from './components/CustomHook';

function App() {
  const [showTopButton, setShowTopButton] = useState(false);

  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    };

    window.addEventListener('load', aos_init);

    // Show "Go to Top" button on scroll
    const handleScroll = () => {
      setShowTopButton(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('load', aos_init);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useDocTitle("Vollmoon Technologies");

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/healthcare" element={<Healthcare />} /> 
        </Routes>

        {/* "Go to Top" Arrow Button */}
        {showTopButton && (
          <div 
            onClick={scrollToTop} 
            className="go-to-top-icon"
            title="Back to Top"
          >
            <IoIosArrowDropupCircle size={50} />
          </div>
        )}
      </ScrollToTop>
    </Router>
  );
}

export default App;
