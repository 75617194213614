    import React, { useState, useEffect } from 'react';
    import NavBar from '../components/Navbar/NavBar';
    import hero1 from '../images/hero1.jpg';
    import hero2 from '../images/hero2.jpg';

    const images = [hero1, hero2];

    const Hero = () => {
        const [currentImage, setCurrentImage] = useState(0);

        useEffect(() => {
            const interval = setInterval(() => {
                setCurrentImage((prevImage) => (prevImage + 1) % images.length);
            }, 5000);
            return () => clearInterval(interval);
        }, []);

        return (
            <div className="hero relative overflow-hidden w-full h-screen bg-gray-100" id="hero">
                {/* Background Images */}
                <div className="absolute top-0 left-0 w-full h-full -z-10 transition-opacity duration-1000">
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Slide ${index + 1}`}
                            className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ${
                                index === currentImage ? 'opacity-30' : 'opacity-0'
                            }`}
                        />
                    ))}
                </div>

                {/* Overlay */}
                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-30 -z-10"></div>

                {/* Navigation Bar */}
                <div className="relative z-10">
                    <NavBar />
                </div>

                {/* Hero Content - Responsive Adjustments */}
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 ">
                    <h1 className="mb-3 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold drop-shadow-lg" style={{ color: "#ffa147" }}>
                        Vollmoon Technologies for your unique business needs
                    </h1>
                

                    <p
  className="text-base sm:text-lg md:text-xl font-semibold tracking-tight mb-5 text-white drop-shadow-md"
  style={{ whiteSpace: 'pre-line' }}
>
  We provide effective and innovative solutions across various industries, helping businesses enhance efficiency, improve quality,
  <br className="hidden lg:block" />
  and reduce costs through smart technology and streamlined processes.
</p>

                    </div>
            </div>
        );
    };

    export default Hero;