import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import NavBar from "./Navbar/NavBar";
import Footer from "../components/Footer";

import zigZagImg from "../images/Healthcare.webp";
import emrImg from "../images/emr.webp";
import medicalImg from "../images/medical.webp";
import teleImg from "../images/teleImg.webp";
import proImg from "../images/proImg.webp";
import remote from "../images/Remote.webp";
import mhealth from "../images/mhealth.webp";
import hl7 from "../images/hl7.webp";
import iot from "../images/iot.webp";

function Healthcare() {
  const [openIndex, setOpenIndex] = useState(null);

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  const handleMouseEnter = (index) => {
    setOpenIndex(index);
  };

  const handleMouseLeave = () => {
    setOpenIndex(null);
  };

  const details = [
    {
      title: "Healthcare Organization Management Software",
      description:
        "Software for managing healthcare organizations, streamlining operations, and ensuring efficient service delivery.",
    },
    {
      title: "Care Management Software",
      description:
        "Tools for tracking patient care plans, enhancing care coordination, and improving patient outcomes.",
    },
    {
      title: "Patient-Centered Software",
      description:
        "Solutions designed to enhance patient engagement, satisfaction, and personalized healthcare experiences.",
    },
    {
      title: "Medical Imaging and Lab Software",
      description:
        "Software for medical imaging, laboratory management, and data analysis to support diagnostics and patient care.",
    },
    {
      title: "Medical Device Software and SaMD",
      description:
        "Software for medical devices, including Software as a Medical Device (SaMD), ensuring compliance and performance.",
    },
    {
      title: "User Software",
      description:
        "User-centric applications tailored to provide seamless interaction and access to healthcare services.",
    },
    {
      title: "Management Software",
      description:
        "Management tools for overseeing healthcare operations, resources, and compliance.",
    },
    {
      title: "End-Customer Software",
      description:
        "Software solutions for end customers, enhancing their access to services and information in the healthcare sector.",
    },
  ];

  return (
    <>
      {/* Fixed Header with NavBar */}
      <NavBar />
      <header className="fixed top-0 left-0 right-0 z-50 bg-white shadow">
        <div className="container mx-auto px-8">
        
        </div>
      </header>

      {/* Main Content */}
      {/* pt-24 ensures content starts below the fixed header */}
      <div className="container mx-auto px-8 pt-24 text-gray-800">
        {/* Page Headings */}
        <h1 className="text-4xl font-bold text-center text-blue-800 mb-4">
          Healthcare
        </h1>
        <h2 className="text-2xl font-bold text-center text-blue-800 mt-4">
          Healthcare Software Development Solutions
        </h2>
        <p className="text-justify text-base leading-relaxed max-w-3xl mx-auto my-6 text-gray-600">
          Transit to digital healthcare and achieve better outcomes with our digitally smart
          healthcare software development services. We utilize the web, mobile, and medical
          devices to enable the next-gen quality of healthcare software solutions.
        </p>

        {/* Solutions List */}
        <div className="flex flex-wrap gap-4 justify-between mt-8">
          {details.map((item, index) => (
            <div
              key={index}
              className="flex-1 min-w-[250px]"
              data-aos="fade-up"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <ul className="list-none pl-0 text-gray-600 text-base">
                <li className="p-4 cursor-pointer flex justify-between items-center">
                  {item.title}
                </li>
                {openIndex === index && (
                  <p className="my-2 p-2 pl-4 text-gray-800 border-l-4 border-blue-800">
                    {item.description}
                  </p>
                )}
              </ul>
            </div>
          ))}
        </div>

        {/* Zigzag Sections */}
        <div className="space-y-12 mt-12">
          {/* Section 1: Even (0) → flex-row */}
          <div data-aos="fade-up">
            <h2 className="text-2xl font-bold text-blue-800 text-center mb-4">
              Healthcare Software Development Services
            </h2>
            <div className="flex flex-col md:flex-row items-center">
              <img
                src={zigZagImg}
                alt="Zigzag Healthcare Solutions"
                className="w-4/5 max-w-xl h-auto rounded-2xl mx-auto mb-4 md:mb-0 md:w-1/2"
              />
              <p className="text-justify max-w-3xl mx-auto md:mx-0 md:w-1/2 md:pl-8">
                Design and development of customized software solutions to enhance patient
                care. End-to-end healthcare software services for organizations and startups.
                Develop solutions that streamline patient records, appointments, and billing to
                improve operational efficiency and enhance patient care. Build secure,
                compliant platforms for managing patient data efficiently, supporting clinical
                decisions, and meeting healthcare regulations.
              </p>
            </div>
          </div>

          {/* Section 2: Odd (1) → flex-row-reverse */}
          <div data-aos="fade-up">
            <h2 className="text-2xl font-bold text-blue-800 text-center mb-4">
              EMR and EHR Software Development
            </h2>
            <div className="flex flex-col md:flex-row-reverse items-center">
              <img
                src={emrImg}
                alt="EMR and EHR Software Development"
                className="w-4/5 max-w-xl h-auto rounded-2xl mx-auto mb-4 md:mb-0 md:w-1/2"
              />
              <p className="text-justify max-w-3xl mx-auto md:mx-0 md:w-1/2 md:pr-8">
                Custom Electronic Health Record (EHR) and Electronic Medical Record (EMR)
                software development. Enhancing patient experience while booking appointments
                and monitoring treatments. Develop tailored EMR and EHR systems for efficient
                storage, access, and management of patient records, enhancing the quality of
                care and clinical workflows. Ensure systems integrate smoothly with existing
                healthcare infrastructure while meeting industry regulations (e.g., HIPAA) for
                data security and compliance.
              </p>
            </div>
          </div>

          {/* Section 3: Even (2) */}
          <div data-aos="fade-up">
            <h2 className="text-2xl font-bold text-blue-800 text-center mb-4">
              Medical Software Development
            </h2>
            <div className="flex flex-col md:flex-row items-center">
              <img
                src={medicalImg}
                alt="Medical Software Development"
                className="w-4/5 max-w-xl h-auto rounded-2xl mx-auto mb-4 md:mb-0 md:w-1/4"
              />
              <p className="text-justify max-w-3xl mx-auto md:mx-0 md:w-1/2 md:pl-8">
                Software as a Medical Device (SaMD) for better monitoring of equipment.
                Management of internal processes and healthcare supply chain. Develop
                specialized software for medical devices, enhancing monitoring, diagnostics,
                and patient care while ensuring regulatory compliance. Create solutions that
                automate internal processes like asset management, insurance claims, and supply
                chain management to improve efficiency and reduce costs.
              </p>
            </div>
          </div>

          {/* Section 4: Odd (3) */}
          <div data-aos="fade-up">
            <h2 className="text-2xl font-bold text-blue-800 text-center mb-4">
              Telemedicine Software Development
            </h2>
            <div className="flex flex-col md:flex-row-reverse items-center">
              <img
                src={teleImg}
                alt="Telemedicine Software Development"
                className="w-4/5 max-w-xl h-auto rounded-2xl mx-auto mb-4 md:mb-0 md:w-1/4"
              />
              <p className="text-justify max-w-3xl mx-auto md:mx-0 md:w-1/2 md:pr-8">
                As one of the best healthcare IT solutions providers, we develop feature-rich
                telemedicine software for your hospital or clinic that facilitates contactless
                treatment and allows physicians to consult, diagnose, and treat patients via
                video calling or chat. Creating an intuitive and accessible interface that
                allows patients and healthcare providers to easily connect, schedule, and
                conduct virtual consultations. Ensuring compliance with healthcare regulations
                like HIPAA by implementing robust security measures to protect patient
                information during virtual interactions.
              </p>
            </div>
          </div>

          {/* Section 5: Even (4) */}
          <div data-aos="fade-up">
            <h2 className="text-2xl font-bold text-blue-800 text-center mb-4">
              Healthcare Software Product
            </h2>
            <div className="flex flex-col md:flex-row items-center">
              <img
                src={proImg}
                alt="Healthcare Software Product"
                className="w-4/5 max-w-xl h-auto rounded-2xl mx-auto mb-4 md:mb-0 md:w-1/4"
              />
              <p className="text-justify max-w-3xl mx-auto md:mx-0 md:w-1/2 md:pl-8">
                Utilizing healthcare software product development services, we help you build
                software products that are cloud-based SaaS models, MVPs, and custom, adhering to
                regulations such as HIPAA and maintaining compliance with legal requirements.
                Healthcare software helps doctors and hospitals track patient history, treatment
                plans, and medications more efficiently, leading to better care and fewer errors.
                It simplifies tasks like appointment scheduling and billing, making it easier for
                patients to book appointments and for providers to manage payments and insurance
                claims. Healthcare software can analyze patient data to identify trends, predict
                health outcomes, and support medical decisions, helping providers deliver more
                personalized and proactive care.
              </p>
            </div>
          </div>

          {/* Section 6: Odd (5) */}
          <div data-aos="fade-up">
            <h2 className="text-2xl font-bold text-blue-800 text-center mb-4">
              Remote Monitoring Software
            </h2>
            <div className="flex flex-col md:flex-row-reverse items-center">
              <img
                src={remote}
                alt="Remote Monitoring Software"
                className="w-4/5 max-w-xl h-auto rounded-2xl mx-auto mb-4 md:mb-0 md:w-1/4"
              />
              <p className="text-justify max-w-3xl mx-auto md:mx-0 md:w-1/2 md:pr-8">
                From wearables to IoT-driven devices, our healthcare software experts build Remote
                Patient Monitoring (RPM) software that allows healthcare professionals to monitor
                patients’ health remotely and provide timely interventions. Remote patient
                monitoring software allows healthcare providers to monitor patients' vital signs,
                such as heart rate, blood pressure, and glucose levels, in real time—enabling
                quicker responses to any concerning changes. By tracking patient health remotely,
                this software helps reduce the need for frequent in-person checkups, making it more
                convenient for patients, especially those with chronic conditions, while also
                freeing up healthcare resources.
              </p>
            </div>
          </div>

          {/* Section 7: Even (6) */}
          <div data-aos="fade-up">
            <h2 className="text-2xl font-bold text-blue-800 text-center mb-4">
              mHealth App Development
            </h2>
            <div className="flex flex-col md:flex-row items-center">
              <img
                src={mhealth}
                alt="mHealth App Development"
                className="w-4/5 max-w-xl h-auto rounded-2xl mx-auto mb-4 md:mb-0 md:w-1/4"
              />
              <p className="text-justify max-w-3xl mx-auto md:mx-0 md:w-1/2 md:pl-8">
                As a renowned healthcare mobile app development company, we develop smart mHealth
                applications for healthcare organizations that allow them to automate processes,
                streamline workflow, manage healthcare records, and deliver better patient care.
                mHealth apps enable users to track various health metrics—such as steps, calories,
                sleep patterns, and heart rate—while offering personalized recommendations to
                improve wellness and reach health goals. These apps allow users to communicate
                directly with healthcare providers through chat, video calls, or appointment
                scheduling, making healthcare more accessible and immediate.
              </p>
            </div>
          </div>

          {/* Section 8: Odd (7) */}
          <div data-aos="fade-up">
            <h2 className="text-2xl font-bold text-blue-800 text-center mb-4">
              HL7/FHIR Integration and Software
            </h2>
            <div className="flex flex-col md:flex-row-reverse items-center">
              <img
                src={hl7}
                alt="HL7/FHIR Integration and Software"
                className="w-4/5 max-w-xl h-auto rounded-2xl mx-auto mb-4 md:mb-0 md:w-1/2"
              />
              <p className="text-justify max-w-3xl mx-auto md:mx-0 md:w-1/2 md:pr-8">
                Our healthcare app development and HL7/FHIR integration help hospitals and surgery
                aggregate data from various systems to create a data repository for better patient
                care, value-based care, quality improvement, research, and informed decision-making.
                HL7/FHIR integration allows different healthcare systems to share patient data
                efficiently, ensuring that information such as medical history, lab results, and
                treatment plans are readily accessible across platforms—improving continuity of care.
                By using FHIR (Fast Healthcare Interoperability Resources) standards, healthcare
                software can integrate with other systems more easily, enabling providers to access a
                comprehensive view of patient data from multiple sources without manual data entry.
              </p>
            </div>
          </div>

          {/* Section 9: Even (8) */}
          <div data-aos="fade-up">
            <h2 className="text-2xl font-bold text-blue-800 text-center mb-4">
              IoT Healthcare Software
            </h2>
            <div className="flex flex-col md:flex-row items-center">
              <img
                src={iot}
                alt="IoT Healthcare Software"
                className="w-4/5 max-w-xl h-auto rounded-2xl mx-auto mb-4 md:mb-0 md:w-1/2"
              />
              <p className="text-justify max-w-3xl mx-auto md:mx-0 md:w-1/2 md:pl-8">
                We develop IoT-based healthcare software (or Internet of Medical Things applications)
                that aggregates patient data via smart sensors and monitors it to yield valuable
                insights for medical professionals. Connecting wearable devices and sensors that
                track vital signs 24/7, this software enables real-time monitoring of health metrics
                such as heart rate, blood pressure, and activity levels. Automated alerts notify both
                patients and providers when irregularities occur, helping prevent emergencies through
                early intervention.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
}

export default Healthcare;
