import React, { useState } from "react";
import NavLinks from "../Navbar/NavLinks";
import { HashLink } from "react-router-hash-link";
import geps from '../../images/logo1.png';
import '../../components/Navbar.css';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="fixed top-0 w-full z-30 bg-white shadow-lg transition duration-300 ease-in-out">
      <div className="flex items-center justify-between py-4 px-6 md:px-12">
        {/* Logo */}
        <div className="flex items-center">
          <HashLink smooth to="/#hero">
            <img alt="vollmoon logo" className="h-auto w-60 md:w-72 mx-auto" src={geps} /> 
          </HashLink>
        </div>

        {/* Hamburger Menu for Mobile */}
        <div className="lg:hidden">
          <button
            className="p-2 rounded-lg text-blue-900 focus:outline-none"
            onClick={handleClick}
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              )}
            </svg>
          </button>
        </div>

        {/* Desktop Menu */}
        <div className="hidden lg:flex space-x-6">
          <NavLinks closeMenu={closeMenu} />
        </div>

        {/* Mobile Dropdown Menu */}
        <div
          className={`fixed top-0 left-0 w-full h-screen bg-white transform ${
            isOpen ? "translate-y-0" : "-translate-y-full"
          } transition-transform duration-300 ease-in-out lg:hidden z-40`}
        >
          <div className="relative h-full">
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 p-2 rounded-lg text-blue-900 focus:outline-none"
              onClick={handleClick}
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            {/* Mobile Nav Links */}
            <div className="flex flex-col items-center justify-center h-full space-y-6">
              <NavLinks closeMenu={closeMenu} />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;