import React from 'react';
import { Link } from 'react-router-dom';
import img from '../images/web.svg';
import img2 from '../images/app.svg';
import img3 from '../images/life-science.svg';
import img4 from '../images/consultation.svg';

const Industries = () => {

    return (
        <div id="industries" className="bg-gray-100 py-12">
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Industries</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">We are deeply committed to the growth and success of our clients.</h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                        {/* Wrap Healthcare section in a Link */}
                        <Link to="/healthcare" className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Healthcare</h2>
                                <p className="text-md font-medium">
                                We deliver state-of-the-art healthcare software tailored for modern needs. From telemedicine and mHealth apps to EHR/EMR systems, our solutions streamline workflows and enhance patient care. With advanced technologies like IoT and AI, we ensure secure, efficient, and scalable healthcare innovations. Partner with us to shape the future of healthcare.
                                </p>
                            </div>
                        </Link>

                        {/* Other sections */}
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                <h2 className="font-semibold my-4 text-2xl text-center">BFSI</h2>
                                <p className="text-md font-medium">
We offer innovative and secure solutions tailored for the BFSI sector, enhancing banking, financial services, and insurance operations. Our expertise in digital banking, insurance management, and financial software ensures seamless, efficient, and compliant systems. With cutting-edge technologies like AI and cloud computing, we empower businesses to meet evolving market demands. Partner with us to drive digital transformation and deliver exceptional customer experiences.
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Life Science</h2>
                                <p className="text-md font-medium">
                                We offer advanced software solutions that empower life sciences organizations to drive research, enhance clinical trials, and accelerate drug development. Our solutions are designed to improve data accuracy, streamline workflows, and support regulatory compliance, helping healthcare providers and researchers focus on what matters most—advancing patient care and scientific discovery. Partner with us to innovate and optimize your life sciences operations.
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Oil & Gas</h2>
                                <p className="text-md font-medium">
                                  We offers cutting-edge software development solutions for the oil and gas industry, driving efficiency and innovation in operations. We specialize in building scalable applications for real-time data monitoring, predictive maintenance, and resource management. Our solutions optimize workflows, ensure regulatory compliance, and enhance operational safety. Partner with us to transform your oil and gas operations with intelligent, customized software
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
}

export default Industries;
