import React from 'react';

const ApproachSection = () => {
  return (
    <section className="w-full bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div 
          className="flex flex-col lg:flex-row justify-between items-start lg:items-stretch text-center lg:text-left space-y-8 lg:space-y-0 lg:space-x-8"
          data-aos="zoom-out"
        >
          {/* We Build */}
          <div className="flex-1 flex flex-col items-center lg:items-start">
            <div className="text-blue-900 mb-4">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="72" 
                height="72" 
                viewBox="0 0 24 24" 
                className="fill-current"
              >
                <path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path>
                <path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path>
              </svg>
            </div>
            <h3 className="text-2xl font-bold text-blue-900">
              We <span className="font-black">Build</span>
            </h3>
            <p className="my-3 text-gray-600 font-medium">
              With a team of engineers bringing over a decade of expertise, we craft scalable, secure, and efficient systems to drive innovation and elevate operations.
            </p>
          </div>
  
          {/* We Collaborate */}
          <div className="flex-1 flex flex-col items-center lg:items-start">
            <div className="text-blue-900 mb-4">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="72" 
                height="72" 
                viewBox="0 0 24 24" 
                className="fill-current"
              >
                <path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
              </svg>
            </div>
            <h3 className="text-2xl font-bold text-blue-900">
              We <span className="font-black">Collaborate</span>
            </h3>
            <p className="my-3 text-gray-600 font-medium">
              We collaborate with your existing team to scale applications or design custom software to simplify processes and boost productivity.
            </p>
          </div>
  
          {/* We Innovate */}
          <div className="flex-1 flex flex-col items-center lg:items-start">
            <div className="text-blue-900 mb-4">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="72" 
                height="72" 
                viewBox="0 0 24 24" 
                className="fill-current"
              >
                <path d="M9 21h6v1a1 1 0 0 1-1 1H10a1 1 0 0 1-1-1v-1zm6.258-1.859c.072-.414.146-.794.146-.794H8.596s.074.38.146.794A1.8 1.8 0 0 0 10.5 20h3a1.8 1.8 0 0 0 1.758-.859zM12 2C7.589 2 4 5.589 4 10c0 3.874 2.5 6.364 4.618 8.049A.994.994 0 0 1 9 19h6c.232 0 .451-.097.618-.269C17.5 16.364 20 13.874 20 10c0-4.411-3.589-8-8-8zm0 2c3.309 0 6 2.691 6 6a6.006 6.006 0 0 1-2.426 4.849A2.98 2.98 0 0 0 15 15H9c-.232 0-.451-.097-.618-.269A6.006 6.006 0 0 1 6 10c0-3.309 2.691-6 6-6zm1 5h-2V7a1 1 0 1 1 2 0v2z"></path>
              </svg>
            </div>
            <h3 className="text-2xl font-bold text-blue-900">
              We <span className="font-black">Innovate</span>
            </h3>
            <p className="my-3 text-gray-600 font-medium">
              Harnessing the power of cutting-edge software development and cloud technologies, we build scalable, secure solutions tailored to meet dynamic business needs and ensure seamless digital transformation.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApproachSection;
