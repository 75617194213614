import React from 'react';

// Static imports for images
import sassLogo from '../images/Sass_logo-Custom.png';
import dynamicDB from '../images/DynamoDB-Custom.png';
import mongodb from '../images/pngwing.com-Custom.png';
import html5Icon from '../images/icons8-html5-100.png';
import javaIcon from '../images/icons8-java-100.png';
import javascriptIcon from '../images/icons8-javascript-100.png';
import awsIcon from '../images/icons8-amazon-web-services-100.png';
import azureIcon from '../images/icons8-azure-100.png';
import gcpIcon from '../images/icons8-google-cloud-100.png';
import mysqlIcon from '../images/icons8-mysql-100.png';
import nodejsIcon from '../images/icons8-nodejs-100.png';
import pythonIcon from '../images/icons8-python-100.png';
import vuejsIcon from '../images/icons8-vue-js-100.png';
import reactNativeIcon from '../images/icons8-react-native-100.png';
import postgresqlIcon from '../images/icons8-postgresql-100.png';
import angularIcon from '../images/icons8-angularjs-100.png';
import dotnetIcon from '../images/icons8-.net-framework-100.png';
import digitaloceanIcon from '../images/DigitalOcean-Logo.wine-Custom.png';
import tailwind from '../images/tailwind.png';
import spring from '../images/spring.png';
import typescript from '../images/typescript.png';
import quarkus from '../images/quarkus.svg';

const Technology = () => {
  // Define the logos for each row
  const row1 = [javascriptIcon, html5Icon, sassLogo, vuejsIcon, javaIcon, nodejsIcon,tailwind];
  const row2 = [angularIcon, dotnetIcon, mongodb, postgresqlIcon, mysqlIcon, pythonIcon,spring];
  const row3 = [awsIcon, azureIcon, gcpIcon, digitaloceanIcon, reactNativeIcon, dynamicDB,typescript,quarkus];

  return (
    <div className="max-w-[1300px] mx-auto px-4 py-12" id ="technology">
      {/* Section Header */}
      <div className="mb-8 py-8">
        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
          Our Expertise
        </h2>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-blue-900"></div>
        </div>
        <h2 className="mt-4 mx-3 text-center text-xl lg:text-2xl font-semibold text-blue-900">
          Empowering businesses with cutting-edge solutions tailored to their needs.
        </h2>
      </div>

      {/* Two Column Layout */}
      <div className="grid md:grid-cols-2 gap-8">
        {/* Left Column */}
        <div>
          <span
            className="bg-white text-[#16426b] text-[18px] px-3 py-2 rounded border border-black 
                       font-medium inline-block transition-transform duration-300 
                       hover:bg-[#1e3a8a] hover:text-white hover:border-[#1e3a8a] hover:scale-105"
          >
            We expertise in
          </span>
          <h1 className="text-[40px] font-semibold text-left mt-4 text-[#1e3a8a]">
          Elevating Your Ideas with Our Powerful Tech Solutions
          </h1>
        </div>

        {/* Right Column */}
        <div>
          <p className="text-lg leading-[1.8]">
          At Vollmoon Technologies, we are proud of our robust and innovative tech ecosystem. Leveraging a diverse range of state-of-the-art tools and frameworks, we empower your vision and turn it into a tangible reality.

          </p>
          <p className="mt-4 text-lg leading-[1.8]">
          Our skilled team excels across multiple domains, enabling us to design customized software solutions that seamlessly align with your business objectives.
          </p>
        </div>
      </div>

      {/* Animated Logos Section */}
      <div className="mt-12 flex justify-center items-center bg-white overflow-hidden">
        <div className="relative overflow-hidden w-full">
          {/* First Row – scrolls left */}
          <div className="flex mb-16 w-[200vw] animate-scrollLeft">
            {row1.concat(row1).map((src, index) => (
              <img
                key={`row1-${index}`}
                src={src}
                alt={`Logo ${index % row1.length + 1}`}
                style={{ width: 'calc(100vw / 6)' }}
                className="h-16 sm:h-20 md:h-24 flex-shrink-0 object-contain px-2 sm:px-4 md:px-6"
              />
            ))}
          </div>

          {/* Second Row – scrolls right */}
          <div className="flex mb-16 w-[200vw] animate-scrollRight">
            {row2.concat(row2).map((src, index) => (
              <img
                key={`row2-${index}`}
                src={src}
                alt={`Logo ${index % row2.length + 1}`}
                style={{ width: 'calc(100vw / 6)' }}
               className="h-16 sm:h-20 md:h-24 flex-shrink-0 object-contain px-2 sm:px-4 md:px-6"
              />
            ))}
          </div>

          {/* Third Row – scrolls left slowly */}
          <div className="flex mb-16 w-[200vw] animate-scrollLeftSlow">
            {row3.concat(row3).map((src, index) => (
              <img
                key={`row3-${index}`}
                src={src}
                alt={`Logo ${index % row3.length + 1}`}
                style={{ width: 'calc(100vw / 6)' }}
             className="h-16 sm:h-20 md:h-24 flex-shrink-0 object-contain px-2 sm:px-4 md:px-6"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technology;
