import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = ({ closeMenu }) => {
    return (
        <>
            <HashLink
                className="px-4 font-extrabold text-[#1E3A84] hover:text-blue-900"
                smooth
                to="/#services"
                onClick={closeMenu}
            >
                Services
            </HashLink>

            <HashLink
                className="px-4 font-extrabold text-[#1E3A84] hover:text-blue-900"
                smooth
                to="/#industries"
                onClick={closeMenu}
            >
                Industries
            </HashLink>

            <HashLink
                className="px-4 font-extrabold text-[#1E3A84] hover:text-blue-900"
                smooth
                to="/#technology"
                onClick={closeMenu}
            >
                Technology
            </HashLink>

            <HashLink
                className="px-4 font-extrabold text-[#1E3A84] hover:text-blue-900"
                smooth
                to="/#about"
                onClick={closeMenu}
            >
                About
            </HashLink>

            <HashLink
                className="px-4 font-extrabold text-[#1E3A84] hover:text-blue-900"
                to="/contact#contact"
                onClick={closeMenu}
            >
                Contact Us
            </HashLink>
        </>
    );
};

export default NavLinks;
